<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 23 23"
  >
    <path
      id="_9004763_chat_message_communication_bubble_icon"
      data-name="9004763_chat_message_communication_bubble_icon"
      d="M22.867,17.3A11.5,11.5,0,1,0,17.3,22.867l4.049,1.011a2.085,2.085,0,0,0,2.529-2.529ZM8.3,14.558a2.085,2.085,0,1,1,2.085-2.085A2.085,2.085,0,0,1,8.3,14.558Zm8.342,0a2.085,2.085,0,1,1,2.085-2.085A2.085,2.085,0,0,1,16.644,14.558Z"
      transform="translate(-0.944 -0.944)"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    iconColor: {
      type: String,
      default: "#29396A",
    },
  },
};
</script>

<style>
</style>