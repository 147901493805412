<template>
  <svg
    id="_9073723_certificate_icon"
    data-name="9073723_certificate_icon"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 22.5 22.499"
  >
    <path
      id="Path_1475"
      data-name="Path 1475"
      d="M0,2.25A2.25,2.25,0,0,1,2.25,0h18A2.25,2.25,0,0,1,22.5,2.25v6l-1.2-.9a6.75,6.75,0,0,0-9.45,9.45L12,17v5.5H2.25A2.25,2.25,0,0,1,0,20.249ZM12,7.5H4.5V6H12ZM4.5,12H9V10.5H4.5Z"
      :fill="iconColor"
      fill-rule="evenodd"
    />
    <path
      id="Path_1476"
      data-name="Path 1476"
      d="M13.25,5A5.25,5.25,0,0,0,9.5,13.924v5.326a.75.75,0,0,0,1.2.6l2.55-1.912,2.55,1.912a.75.75,0,0,0,1.2-.6V13.924A5.25,5.25,0,0,0,13.25,5ZM11,17.749V14.994a5.263,5.263,0,0,0,4.5,0v2.755L13.7,16.4a.75.75,0,0,0-.9,0Z"
      transform="translate(3.999 2.5)"
      :fill="iconColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    iconColor: {
      type: String,
      default: "#29396A",
    },
  },
};
</script>

<style>
</style>