<template>
  <div class="w-full">
    <HomeAnimationVue class="z-6" />
    <!-- <div
      class="bg-div py-20 px-4 bg-cover md:h-screen"
      :style="`background-image: url(${imageUrl})`"
    >
      <div
        class="md:w-1024 mx-auto flex items-center flex-col-reverse md:flex-row justify-between"
      >
        <div class="md:w-6/12 mt-20 text-left">
          <h1 class="text-4xl font-bold text-softGrey">
            Most Advanced AI driven,<br />
            English learning system for learners of all ages
          </h1>
          <p class="mt-8 text-base text-softGrey text-justify">
            Built by educators for educators, neo’s English Learning solutions
            allows learners ages 4 to adult achieve their English fluency goals
            regardless of whether your institution requires fully online
            instruction or a blend of classroom and online.
          </p>
          <RoundedButtonVue
            class="mt-20"
            title="Learn more"
            bgColor="bg-lightBlue"
            textColor="text-white"
            width="w-max"
          />
        </div>
        <LottieAnimationVue
          class="hidden md:block md:w-72 -z-10 mt-10 mr-20"
          :animationData="animationData"
        />
      </div>
    </div> -->
    <div
      class="py-12 mx-auto text-left bg-fixed bg-center bg-contain bg-no-repeat flex flex-col md:flex-row items-center"
      style="height: 100vh"
      :style="`background-image: url(${imageUrlWhyneo})`"
      ref="scrollToElement"
    >
      <div class="w-full py-8 bg-softGrey80">
        <div class="md:w-768 lg:w-1024 md:mt-0 mx-auto">
          <div class="px-4 md:px-0 text-pulpBlue">
            <h1 class="mb-4 text-3xl text-center font-semibold">
              Why neo?
            </h1>
            <p class="text-lg text-justify">
              Based on nexgen’s big data from more than 25 million global
              English learners, neo helps learners set and achieve their fluency
              goals—guaranteed. Fully aligned to the Common European Framework
              of Reference for Languages (CEFR), neo certifies learners’ English
              skills every step of the way.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="md:h-screen py-12 mx-auto text-left bg-fixed bg-center bg-contain bg-no-repeat flex flex-col items-center justify-center"
      :style="`background-image: url(${imageUrlWhyneo})`"
      :class="{ 'text-xl': isMobile, 'text-2xl': !isMobile }"
    >
      <div class="w-full">
        <div class="w-full py-8 bg-softGrey80">
          <div
            class="md:w-768 lg:w-1024 m-auto px-4 text-pulpBlue text-center md:px-0"
          >
            <h1 class="mb-4 text-3xl font-semibold">
              How We Do It
            </h1>
            <p class="text-lg outline-1 highlight">
              From testing to reporting, neo's comprehensive solutions are
              backed by advanced technologies that help administrators focus on
              what's really important.
            </p>
          </div>
        </div>
        <div class="px-4 md:px-0 pt-0 text-left">
          <div
            class="md:w-768 lg:w-1024 mx-auto flex flex-col md:flex-row flex-wrap justify-between items-start"
          >
            <!-- <div class="w-4/12 mt-10"> -->
            <div class="w-full md:w-4/12 mt-2">
              <div class="md:h-44 lg:h-36 px-4 py-2 bg-softGrey">
                <h5 class="text-lg text-primary font-semibold">neoAssess</h5>
                <p class="text-textBlack text-base">
                  Learners can take our Assessment Test on our cloud-based
                  testing platform and can begin to study in minutes.
                </p>
              </div>
            </div>
            <div class="w-full md:w-4/12 mt-2 md:px-2">
              <div class="md:h-44 lg:h-36 px-4 py-2 bg-softGrey">
                <h5 class="text-lg text-primary font-semibold">neoDashboard</h5>
                <p class="text-textBlack text-base">
                  The neoDashboard allows you to easily set up your
                  organization, assign students to classes, view grades, and
                  much more.​
                </p>
              </div>
            </div>
            <div class="w-full md:w-4/12 mt-2">
              <div class="md:h-44 lg:h-36 px-4 py-2 bg-softGrey">
                <h5 class="text-lg text-primary font-semibold">
                  Backed by 30+ Years of Success
                </h5>
                <p class="text-textBlack text-base">
                  neo is a comprehensive learning system based on big data from
                  more than 25 million learners from around the world.
                </p>
              </div>
            </div>
            <div class="w-full md:w-4/12 mt-2">
              <div class="md:h-44 lg:h-36 px-4 py-2 bg-softGrey">
                <h5 class="text-lg text-primary font-semibold">neoStudy</h5>
                <p class="text-textBlack text-base">
                  Learners study at their own pace and at the skill level that
                  is right for them. They earn certificates as they meet each
                  CEFR-level requirement.
                </p>
              </div>
            </div>
            <div class="w-full md:w-4/12 mt-2 md:px-2">
              <div class="md:h-44 lg:h-36 px-4 py-2 bg-softGrey">
                <h5 class="text-lg text-primary font-semibold">
                  Advanced Technologies
                </h5>
                <p class="text-textBlack text-base">
                  Artificial intelligence (AI) and speech recognition technologies
                  work together for powerful results.
                </p>
              </div>
            </div>
            <div class="w-full md:w-4/12 mt-2">
              <div class="md:h-44 lg:h-36 px-4 py-2 bg-softGrey">
                <h5 class="text-lg text-primary font-semibold">myneo App</h5>
                <p class="text-textBlack text-base">
                  The myneo productivity app helps learners stay focused on
                  their English language goals by keeping all their neo-related
                  information in one place.
                </p>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="md:w-768 lg:w-1024 text-center m-auto border-t px-4 md:px-20 py-12">
      <h1 class="mt-2 mb-6 text-3xl text-primary font-semibold">
        Success Stories
      </h1>
      <p class="text-textBlack text-base">
        Check out these noteworthy success stories and find out how
        organizations just like yours have achieved their English language goals
        with nexgen’s neo.​
      </p>
    </div>
    <div class="px-4 md:px-0 text-left">
      <div class="md:w-768 lg:w-1024 mx-auto flex-container flex-col md:flex-row">
        <div
          v-for="(data, i) in successStories"
          :key="i"
          class="flex-item ml-0 my-2 md:my-0 md:w-3/12 p-6 bg-softGrey"
          :class="{ 'md:mx-4': i === 1 }"
        >
          <div class="pb-4">
            <h5 class="mb-2 text-lg text-primary font-semibold">
              {{ data.title }}
            </h5>
            <p class="text-textBlack text-base">
              {{ data.story }}
            </p>
          </div>
          <!-- <div class="pt-4 flex">
            <img
              class="rounded-full h-10"
              :src="getImageUrl(data.image)"
              alt="dummy-profile-pic-male1"
            />
            <div class="ml-2 text-sm text-primary text-left flex flex-col">
              <span>{{ data.name }}</span>
              <span class="text-xs font-semibold text-primary">{{
                data.date
              }}</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="px-20 pb-32">
      <rounded-button-vue
        class="mt-10 mx-auto"
        title="See more"
        bgColor="bg-primary"
        textColor="text-white"
        width="w-max md:w-3/12 lg:w-2/12"
        @click.native="$router.push('/mediageneral')"
      />
    </div>
  </div>
</template>

<script>
import RoundedButtonVue from "../components/buttons/RoundedButton.vue";
import HomeAnimationVue from "../components/HomeAnimation.vue";
import LottieAnimationVue from "../components/lottie/LottieAnimation.vue";

export default {
  name: "Home",
  components: {
    RoundedButtonVue,
    LottieAnimationVue,
    HomeAnimationVue,
  },
  data() {
    return {
      imageUrl: require("@/assets/images/bgspace.png"),
      imageUrl2: require("@/assets/images/bgspace2.png"),
      // imageUrlWhyneo: require("@/assets/images/icons/home-page-new-image-.png"),
      isMobile: window.innerWidth <= 768, // Adjust the breakpoint as needed
      mobileImageUrl: require("@/assets/images/icons/home-page-new-image-mobile.jpeg"), // Set your mobile image URL here
      desktopImageUrl: require("@/assets/images/icons/home-page-new-image-.png"), // Set your desktop image URL here
      successStories: [
        {
          id: 1,
          title:
            "Adult English Learners Earn Their First Certificate in 9 Weeks",
          story:
            "Rita Simons-Santiago teaches Adult English to parishioners and parents at Prince of Peace Parish in Milwaukee, WI. After just 9 weeks of their neo+ English study, 7 of her 15 learners were able to earn their CEFR-aligned certificates.",
          image: "dummy-profile-pic-male1.jpeg",
          name: "Rita Simons",
          date: "23 Nov 2022",
        },
        {
          id: 2,
          title: "Mastering High-Level English Proficiency in 6 Months",
          story:
            "Karrar Hayder Razoqui is an aviation engineer in Iraq. Starting his English journey only six months before, Karrar has been able to improve his English and advance his career goals .​",
          image: "dummy-profile-pic-male1.jpeg",
          name: "Karrar Hyder",
          date: "23 Nov 2022",
        },
        {
          id: 3,
          title: "First neo Student to Get Certificate",
          story:
            "Mr. Ratdiman is a Loss Prevention Supervisor at the JW Marriott Hotel in Jakarta (Indonesia). During a pilot program at the hotel, Mr. Ratdiman became the first student to earn a certificate –an A1—while studying neo.​",
          image: "dummy-profile-pic-male1.jpeg",
          name: "Ratdiman",
          date: "23 Nov 2022",
        },
      ],
      animationData: require("@/assets/json/certification_frame.json"), // Import the Lottie JSON animation data
    };
  },
  mounted() {
    // window.setTimeout(() => {
    //   this.scrollToElement();
    // }, 5200);
    if (window.innerWidth <= 425) {
      window.setTimeout(() => {
        this.scrollToElement();
      }, 2000);
    } else {
      window.setTimeout(() => {
        this.scrollToElement();
      }, 5200);
    }
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    imageUrlWhyneo() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    },
    getImageUrl(fileName) {
      // Assuming your images are located in the assets/images folder
      return require(`@/assets/images/${fileName}`);
    },
    scrollToElement() {
      // Scroll to the div with the ref "scrollToElement"
      this.$refs.scrollToElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style scoped>
/* Use Tailwind CSS utility classes */
.flex-container {
  display: flex;
}

/* Apply `flex-1` class to make the flex items take equal space */
.flex-item {
  flex: 1;
}

/* Optional: Add spacing between flex items */
.flex-item + .flex-item {
  /* margin-left: 10px; */
  /* Adjust as needed */
}

/* CSS talk bubble */
.talk-bubble {
  /* margin: 40px; */
  display: inline-block;
}

.down-arrow {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;

  border-top: 25px solid #49c5fe;

  margin: 0 2rem 2rem;
}

.outlined {
  text-shadow: -0.5px -0.5px 0 #fafafa, 0.5px -0.5px 0 #fafafa,
    -0.5px 0.5px 0 #fafafa, 0.5px 0.5px 0 #fafafa;
}
</style>