<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 38 41.01"
  >
    <g id="Group_1737" data-name="Group 1737" transform="translate(-6 -4.99)">
      <path
        id="Path_1568"
        data-name="Path 1568"
        d="M15.2,19.97a9.991,9.991,0,0,0,18.17,3.49,7.01,7.01,0,0,0,2.44-3.84,2.5,2.5,0,0,0-.8-4.58V11.97a6.986,6.986,0,0,0-6.98-6.98H21.98A6.986,6.986,0,0,0,15,11.97v3.07a2.493,2.493,0,0,0,.2,4.92ZM25.84,14a5.381,5.381,0,0,0,4.53-2.47L33,13.5v3.25H31.5a3.25,3.25,0,0,0-6,0h-1a3.25,3.25,0,0,0-6,0H17V14Zm4.41,4a1.75,1.75,0,1,1-1.75-1.75A1.758,1.758,0,0,1,30.25,18Zm-7,0a1.75,1.75,0,1,1-1.75-1.75A1.758,1.758,0,0,1,23.25,18Zm-6.24.25h1.25a3.25,3.25,0,0,0,6.48,0h.53a3.25,3.25,0,0,0,6.48,0H33a7.935,7.935,0,0,1-1.13,3.85,4.958,4.958,0,0,1-2.86.9h-3a1,1,0,1,0,0,2h2.86a7.894,7.894,0,0,1-3.86,1,8.012,8.012,0,0,1-7.99-7.75Z"
        :fill="iconColor"
      />
      <path
        id="Path_1569"
        data-name="Path 1569"
        d="M35,29H15a9.014,9.014,0,0,0-9,9v7a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V31h3.09a5.993,5.993,0,0,0,11.82,0H34V45a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V38A9.014,9.014,0,0,0,35,29ZM8,40h4a1,1,0,0,0,0-2H8a7.013,7.013,0,0,1,6-6.93V44H8Zm17-6a4.011,4.011,0,0,1-3.87-3h7.74A4.011,4.011,0,0,1,25,34ZM36,44V31.07A7.013,7.013,0,0,1,42,38H38a1,1,0,0,0,0,2h4v4Z"
        :fill="iconColor"
      />
      <path
        id="Path_1570"
        data-name="Path 1570"
        d="M30,38H20a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H30a1,1,0,0,0,1-1V39A1,1,0,0,0,30,38Zm-1,6H21V40h8Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#368fe9",
    },
  },
};
</script>

<style>
</style>