<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 35.98 40.825"
  >
    <g
      id="Group_1733"
      data-name="Group 1733"
      transform="translate(-6.47 -5.175)"
    >
      <circle
        id="Ellipse_245"
        data-name="Ellipse 245"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(19.5 20)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_246"
        data-name="Ellipse 246"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(26.5 20)"
        :fill="iconColor"
      />
      <path
        id="Path_1563"
        data-name="Path 1563"
        d="M8.27,11.7l6.23,3.46v2.88a2.493,2.493,0,0,0,.2,4.92,10,10,0,0,0,19.6,0,2.493,2.493,0,0,0,.2-4.92V15.16l4.5-2.5v8.33a1,1,0,0,0,2,0V11.42a.645.645,0,0,0-.37-.9L25.43,5.34a2.7,2.7,0,0,0-1.86,0L8.37,10.52a.641.641,0,0,0-.1,1.17ZM16.5,15V12.81a40.649,40.649,0,0,1,16,0V15Zm16,6a8,8,0,0,1-16,0V19h1.76a10.261,10.261,0,0,0,6.06-2H32.5Z"
        :fill="iconColor"
      />
      <path
        id="Path_1564"
        data-name="Path 1564"
        d="M34.74,32l-3.8-1.9a1.016,1.016,0,0,0-1.37.5l-3.29,7.67L24.83,33.2a.358.358,0,0,0-.69,0l-1.45,5.07L19.4,30.6a1.016,1.016,0,0,0-1.37-.5L14.23,32a8.016,8.016,0,0,0-7.76,8v5a1,1,0,0,0,1,1h6.5a1,1,0,0,0,0-2H8.47V40a6,6,0,0,1,6-6,1,1,0,0,0,.45-.11l3.05-1.53,4.98,11.63H22l-5.7-8.55a1,1,0,0,0-1.67,1.11l6,9a1.008,1.008,0,0,0,.83.45h6a1,1,0,0,0,.83-.45l6-9a1,1,0,0,0-1.67-1.11l-5.7,8.55h-.95l4.98-11.63L34,33.89a1,1,0,0,0,.45.11,6,6,0,0,1,6,6v4h-5.5a1,1,0,0,0,0,2h6.5a1,1,0,0,0,1-1V40a8.016,8.016,0,0,0-7.76-8Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "orange",
    },
  },
};
</script>

<style>
</style>