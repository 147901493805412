import axios from 'axios'

const modulePostEmail = {
  namespaced: true,
  state: () => ({
    stateEmail: null
  }),
  mutations: {
    muttEmail (state, muttEmail) {
      state.stateEmail = muttEmail
    }
  },
  actions: {
    postMail ({ commit, dispatch }, data) {
      // console.log(data);
      axios({
				method: 'POST',
				url: `${process.env.VUE_APP_API_EMAIL}/api/notifications/send`,
				data: data,
        headers: {'Authorization': `${process.env.VUE_APP_API_TOKEN}` }
			})
      .then(res => {
        // console.log(res);
        commit('muttEmail',{
					data: res
				})
      }).catch(err => {
        console.log(err)
      })
    }
  },
  getters: {
    getEmail: state => {
      const data = state.stateEmail

      if (data == null) {
        return false
      } else if (data.data.status != 200) {
        return false
      } else {
        return true
      }
    }
  }
}
export default modulePostEmail
