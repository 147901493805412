import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios'
import router from '../router/index'
import moduleGetMedia from './modules/ModuleGetMedia'
import modulePostEmail from './modules/ModulePostEmail'
import moduleGetCertification from './modules/ModuleGetCertification';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stateToken: null,
    stateRoleId: null,
    stateError401: false,
    stateLoginStatus: [],
    stateHideHeader: ""
  },
  mutations: {
    muttToken(state, muttToken) {
      state.stateToken = muttToken
    },
    muttRoleId(state, muttRoleId) {
      state.stateRoleId = muttRoleId
    },
    muttAuthError(state, muttAuthError) {
      state.stateError401 = muttAuthError
    },
    muttLoginStatus(state, muttLoginStatus) {
      state.stateLoginStatus = muttLoginStatus
    },
    muttHideHeader(state, muttHideHeader) {
      state.stateHideHeader = muttHideHeader
    },
    muttLogout(state) {
      state.stateToken = null;
      localStorage.removeItem("vuex");
      state.stateRoleId = null;
      state.stateError401 = false;
      state.stateLoginStatus = [];
    },
  },
  actions: {
    actLogin({ commit, dispatch }, data) {
      axios.post(`${process.env.VUE_APP_API_BASEURL}/api/v1/jwt/token-request`, data)
        .then(res => {
          //  console.log(res)
          commit('muttToken', {
            data: res
          })
          dispatch('actGetRole', data)
          // router.push('/medialibrary')
        })
        .catch(err => {
          console.log(err)
          commit('muttAuthError', {
            data: true
          }),
            commit('muttLoginStatus', {
              data: "Wrong email or password"
            })
          dispatch('actLoginFailed')
        })
    },
    actGetRole({ commit, dispatch, state }, data) {
      axios.get(`${process.env.VUE_APP_API_BASEURL}/api/v1/sso/user/${data.username}`, {
        headers: { 'X-Dyned-Tkn': state.stateToken.data.data.token }
      })
        .then(res => {
          // console.log(res)
          commit('muttRoleId', {
            data: res
          })
          dispatch('actValidateRoleId')
        })
        .catch(err => {
          console.log(err)
          dispatch('actLoginFailed')
          commit('muttAuthError', {
            data: true
          }),
            commit('muttLoginStatus', {
              data: err
            })
        })
    },
    actValidateRoleId({ state, commit }) {
      const data = state.stateRoleId.data.data.acl.ncw.roles[0]

      if (data == "agent" || data == "reseller" || data == "super_admin" || data == "region_admin" || data == "teacher" ||
        data == "country_admin" || data == "organization_admin") {
        router.push('/medialibrary')
      } else {
        commit('muttLogout')
        commit('muttAuthError', {
          data: true
        }),
          commit('muttLoginStatus', {
            data: "Undefined User"
          })
      }
    },
    actLogout({ commit, dispatch }) {
      commit('muttLogout')
      router.push('/partnerlogin')
    },
    actLoginFailed({ state, commit }) {
      commit('muttToken', {
        data: null
      })
      commit('muttRoleId', {
        data: []
      })
      localStorage.removeItem("vuex");
    },
  },
  getters: {
    getAuth: state => {
      if (state.stateRoleId == null) {
        return false
      } else if (state.stateRoleId.data.status == 200) {
        return true
      } else {
        return false
      }
    },
    // getLoginRes: state => {
    //   if (state.stateToken.data.status == 404) {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
  modules: {
    moduleGetMedia,
    modulePostEmail,
    moduleGetCertification
  },
  plugins: [createPersistedState()]
})
