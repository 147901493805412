<template>
  <svg :width="width" :height="height" :fill="iconColor" viewBox="0 0 24 24">
    <path
      d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    iconColor: {
      type: String,
      default: "#29396A",
    },
  },
};
</script>

<style>
</style>