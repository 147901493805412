<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 35.002 41.32"
  >
    <g
      id="Group_1759"
      data-name="Group 1759"
      transform="translate(-11.29 -4.34)"
    >
      <path
        id="Path_1599"
        data-name="Path 1599"
        d="M31.96,18.8H27.41a.649.649,0,0,1-.65-.65V13.6a.649.649,0,0,1,.65-.65h3.9a.65.65,0,1,1,0,1.3H28.06V17.5h3.9a.65.65,0,1,1,0,1.3Z"
        :fill="iconColor"
      />
      <path
        id="Path_1600"
        data-name="Path 1600"
        d="M30.5,17a.635.635,0,0,1-.46-.19l-1.3-1.3a.651.651,0,0,1,.92-.92l.88.88,3.45-2.87a.653.653,0,1,1,.84,1l-3.9,3.25a.655.655,0,0,1-.42.15Z"
        :fill="iconColor"
      />
      <path
        id="Path_1601"
        data-name="Path 1601"
        d="M31.96,27.62H27.41a.649.649,0,0,1-.65-.65V22.42a.649.649,0,0,1,.65-.65h3.9a.65.65,0,1,1,0,1.3H28.06v3.25h3.9a.65.65,0,1,1,0,1.3Z"
        :fill="iconColor"
      />
      <path
        id="Path_1602"
        data-name="Path 1602"
        d="M31.96,35.54H27.41a.649.649,0,0,1-.65-.65V30.34a.649.649,0,0,1,.65-.65h3.9a.65.65,0,1,1,0,1.3H28.06v3.25h3.9a.65.65,0,0,1,0,1.3Z"
        :fill="iconColor"
      />
      <path
        id="Path_1603"
        data-name="Path 1603"
        d="M30.5,25.95a.635.635,0,0,1-.46-.19l-1.3-1.3a.651.651,0,0,1,.92-.92l.88.88,3.45-2.87a.653.653,0,1,1,.84,1l-3.9,3.25a.655.655,0,0,1-.42.15Z"
        :fill="iconColor"
      />
      <path
        id="Path_1604"
        data-name="Path 1604"
        d="M33.92,45.66H16.21a4.929,4.929,0,0,1-4.92-4.92V9.26a4.929,4.929,0,0,1,4.92-4.92H33.92a4.929,4.929,0,0,1,4.92,4.92V24.02a.98.98,0,1,1-1.96,0V9.26a2.949,2.949,0,0,0-2.95-2.95H16.22a2.949,2.949,0,0,0-2.95,2.95V40.74a2.949,2.949,0,0,0,2.95,2.95H33.93a2.949,2.949,0,0,0,2.95-2.95V31.89a.98.98,0,0,1,1.96,0v8.85a4.929,4.929,0,0,1-4.92,4.92Z"
        :fill="iconColor"
      />
      <path
        id="Path_1605"
        data-name="Path 1605"
        d="M36.87,10.24H13.27a.98.98,0,1,1,0-1.96H36.88a.98.98,0,1,1,0,1.96Z"
        :fill="iconColor"
      />
      <path
        id="Path_1606"
        data-name="Path 1606"
        d="M36.87,39.76H13.27a.98.98,0,0,1,0-1.96H36.88a.98.98,0,1,1,0,1.96Z"
        :fill="iconColor"
      />
      <path
        id="Path_1607"
        data-name="Path 1607"
        d="M28.02,42.71h-5.9a.98.98,0,0,1,0-1.96h5.9a.98.98,0,1,1,0,1.96Z"
        :fill="iconColor"
      />
      <path
        id="Path_1608"
        data-name="Path 1608"
        d="M24.09,17.13H19.17a.98.98,0,0,1,0-1.96h4.92a.98.98,0,1,1,0,1.96Z"
        :fill="iconColor"
      />
      <path
        id="Path_1609"
        data-name="Path 1609"
        d="M24.09,25.98H19.17a.98.98,0,0,1,0-1.96h4.92a.98.98,0,1,1,0,1.96Z"
        :fill="iconColor"
      />
      <path
        id="Path_1610"
        data-name="Path 1610"
        d="M24.09,33.85H19.17a.98.98,0,1,1,0-1.96h4.92a.98.98,0,1,1,0,1.96Z"
        :fill="iconColor"
      />
      <path
        id="Path_1611"
        data-name="Path 1611"
        d="M46.06,23.33a1.692,1.692,0,0,0-2.3-.62L30.13,30.58a.689.689,0,0,0-.23.22l-1.21,1.84a.7.7,0,0,0,.58,1.08h.04l2.2-.13a.693.693,0,0,0,.31-.09l13.63-7.87a1.692,1.692,0,0,0,.62-2.3Z"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_248"
        data-name="Ellipse 248"
        cx="0.98"
        cy="0.98"
        r="0.98"
        transform="translate(15.24 15.17)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_249"
        data-name="Ellipse 249"
        cx="0.98"
        cy="0.98"
        r="0.98"
        transform="translate(15.24 24.02)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_250"
        data-name="Ellipse 250"
        cx="0.98"
        cy="0.98"
        r="0.98"
        transform="translate(15.24 31.89)"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#3dd3bd",
    },
  },
};
</script>

<style>
</style>