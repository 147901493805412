<template>
  <div
    class="bg-div py-14 px-4 md:px-0 bg-cover"
    :style="`background-image: url(${imageUrl})`"
  >
    <div
      class="md:w-768 lg:w-1024 mx-auto flex flex-col md:flex-row justify-between"
    >
      <div class="md:w-4/12">
        <router-link to="/"
          ><img
            class="h-14"
            src="@/assets/images/neo-nexgen-blue.png"
            alt="neo-nexgen-blue"
        /></router-link>
        <p class="mt-2 pr-4 text-justify text-sm text-softGrey italic">
          Better English. Better Life.
        </p>
      </div>
      <div
        v-for="(data, i) in footerNav"
        :key="i"
        class="mt-10 md:mt-0 md:w-2/12 md:ml-4 text-left text-white"
      >
        <h5 class="font-semibold text-secondary mb-2">
          <router-link :to="data.title.url" @click.native="showModal = false">{{
            data.title.name
          }}</router-link>
        </h5>
        <ul :class="{ '-mt-2': data.title.header }">
          <!-- <li
            class="my-2 cursor-pointer"
            v-for="(nav, i) in data.navs"
            :key="i"
            :class="getHeaderClass(nav)"
          >
            <router-link :to="nav.url" @click.native="showModal = false">{{
              nav.name
            }}</router-link>
          </li> -->
          <RouterLinkVue
            v-for="(nav, index) in data.navs"
            :key="index"
            :to="nav.url"
          >
            <li
              class="my-2 text-sm font-medium cursor-pointer"
              :class="{ 'font-extrabold': nav.header }"
            >
              {{ nav.name }}
            </li>
          </RouterLinkVue>
        </ul>
      </div>
      <h5 class="text-softGrey text-xs absolute bottom-4">
        Version 1.0.1-202406121411 <br />© {{ currentYear }} Nexgen English Online, Co. All rights reserved.
      </h5>
    </div>
  </div>
</template>

<script>
import RouterLinkVue from "../RouterLink.vue";

export default {
  components: {
    RouterLinkVue,
  },
  data() {
    return {
      imageUrl: require("@/assets/images/bgspace.png"),
      currentYear: new Date().getFullYear(),
      footerNav: [
        {
          title: { name: "Teachers", url: "teacher", header: false },
          navs: [
            { name: "Preschool", url: "preschool", header: false },
            {
              name: "Elementary School",
              url: "elementaryschool",
              header: false,
            },
            { name: "Middle School", url: "middleschool", header: false },
            { name: "High School", url: "highschool", header: false },
            { name: "Higher Learning", url: "higherlearning", header: false },
          ],
        },
        {
          title: { name: "", url: "#", header: true },
          navs: [
            { name: "Admin", url: "admin", header: true },
            { name: "Nurses", url: "nurses", header: true },
            { name: "Human Resources", url: "humanresources", header: true },
            { name: "Contact Us", url: "/contactus", header: true },
            { name: "About Us", url: "/about", header: true },
          ],
        },
        {
          title: { name: "Students", url: "students", header: false },
          navs: [
            {
              name: "School or University User",
              url: "schooluser",
              header: false,
            },
            { name: "Business User", url: "businessuser", header: false },
          ],
        },
        {
          title: { name: "Resources", url: "#", header: false },
          navs: [
            // { name: "neoAgent", url: "neoagentresources", header: false },
            { name: "Media", url: "mediageneral", header: false },
            {
              name: "neo Student Certificate Verification",
              url: "neostudentcertificateverification",
              header: false,
            },
            {
              name: "DynEd Student Certificate Verification",
              url: "/dynedstudentcertificateverification",
              header: false,
            },
            {
              name: "Statement of Results Verification",
              url: "https://neo-verify.com/",
              header: false,
            },
            {
              name: "System Requirements",
              url: "support",
              header: false,
            },
          ],
        },
      ],
    };
  },
  methods: {
    getHeaderClass(nav) {
      return {
        "text-secondary font-semibold text-base": nav.header,
        "text-softGrey text-sm": !nav.header,
      };
    },
  },
};
</script>

<style>
</style>