<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 34 42"
  >
    <g id="Group_1743" data-name="Group 1743" transform="translate(-8 -4.01)">
      <path
        id="Path_1578"
        data-name="Path 1578"
        d="M41.98,13.81h0a1.172,1.172,0,0,0-.09-.24c0-.02-.02-.04-.03-.06a.652.652,0,0,0-.16-.21l-9-9a1.66,1.66,0,0,0-.21-.16l-.06-.03a.681.681,0,0,0-.24-.08h-.01a1.108,1.108,0,0,0-.19-.02H9a1,1,0,0,0-1,1v40a1,1,0,0,0,1,1H41a1,1,0,0,0,1-1V14A.584.584,0,0,0,41.98,13.81ZM33,13V7.41l2.79,2.79,2.79,2.79H32.99ZM10,44V6H31v4.78a11.011,11.011,0,0,0-10.8-.68,1,1,0,0,0,.88,1.8,8.978,8.978,0,0,1,9.93,1.41V14a1,1,0,0,0,1,1h.48a9,9,0,1,1-16.48,5,9.074,9.074,0,0,1,1.2-4.5,1,1,0,0,0-1.73-1.01,10.995,10.995,0,0,0,1.53,13.04V39.99a.976.976,0,0,0,.5.86.993.993,0,0,0,1,0l6.5-3.72,6.5,3.72a.958.958,0,0,0,.5.13.88.88,0,0,0,.5-.14,1,1,0,0,0,.5-.86V27.52a10.991,10.991,0,0,0,1.8-12.54h5.2v29H10Zm9-14.79a10.808,10.808,0,0,0,2,1.03V37a.847.847,0,0,0,.01.13L19,38.28V29.22Zm4,1.6a10.621,10.621,0,0,0,4,0v5.18l-1.5-.86a.975.975,0,0,0-.99,0l-1.5.86V30.81ZM29,37V30.24a11.455,11.455,0,0,0,2-1.03v9.06l-2.01-1.15s.01-.08.01-.13Z"
        :fill="iconColor"
      />
      <path
        id="Path_1579"
        data-name="Path 1579"
        d="M21.57,25,25,23.2,28.43,25l-.66-3.82,2.78-2.7-3.84-.56L25,14.45l-1.71,3.47-3.84.56,2.78,2.7Z"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_247"
        data-name="Ellipse 247"
        cx="1"
        cy="1"
        r="1"
        transform="translate(17.35 11.53)"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#ffd400",
    },
  },
};
</script>

<style>
</style>