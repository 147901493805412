<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 23 22.99"
  >
    <g id="CLOSE" transform="translate(-9.047 -9.058)">
      <path
        id="Path_17"
        data-name="Path 17"
        d="M9.5,29.416l9.12-9.12-8.606-8.606a1.54,1.54,0,0,1,2.177-2.177L20.8,18.118l8.606-8.606a1.54,1.54,0,1,1,2.177,2.177l-19.9,19.9A1.54,1.54,0,0,1,9.5,29.416Z"
        transform="translate(0 0)"
        :fill="iconColor"
      />
      <path
        id="Path_18"
        data-name="Path 18"
        d="M25.749,23.072l5.464,5.464a1.54,1.54,0,1,1-2.177,2.177l-5.464-5.464a1.54,1.54,0,1,1,2.177-2.177Z"
        transform="translate(0.38 0.366)"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    iconColor: {
      type: String,
      default: "#29396A",
    },
  },
};
</script>

<style>
</style>