<template>
  <div id="app" class="font-sfproregular relative">
    <TopNavigationVue targetDivClass="bg-div" v-show="!hasWebview" />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
    <BottomNavigationVue v-show="!hasWebview" />
    <div v-show="!hasWebview" class="flex fixed bottom-5 right-10">
      <div
        class="mr-3 bg-primary rounded-md p-2 cursor-pointer"
        @click="$router.push('/studentcertificateverification')"
      >
        <certificate-icon-vue :height="20" :width="20" iconColor="#fff" />
      </div>
      <div
        class="bg-primary rounded-md p-2 cursor-pointer"
        @click="$router.push('/contactus')"
      >
        <MailIconVue :height="20" :width="20" iconColor="#fff" />
      </div>
    </div>
    <BasicModalsVue
      :show="showCertificationModal"
      @update:show="showCertificationModal = $event"
    >
      <CloseIconVue
        :width="20"
        :height="20"
        iconColor="#111"
        class="absolute top-7 right-20 cursor-pointer"
        @click.native="closeCertificationModal"
      />
      <div class="h-full flex items-center justify-center">
        <div
          class="md:w-1024 py-14 mx-auto flex flex-col md:flex-row items-start justify-end"
        >
          <img
            class="w-64"
            src="@/assets/images/icons/Group1608.png"
            alt="Group1608"
          />
          <div class="w-8/12 mt-10">
            <h3 class="text-2xl font-black">
              Student Certification Verification
            </h3>
            <p class="w-10/12 py-4">
              Because our students work hard to achieve their certified English
              skills, neo works hard to ensure others don't take the easy road
              to certification.
            </p>
            <input
              class="w-8/12 p-2 rounded-sm border border-softGrey"
              type="text"
              placeholder="E:G Sr-1F-A04f-Xxxx-Xx"
            />
            <RoundedButtonVue
              v-if="!showCertificateResult"
              title="Verify"
              bgColor="bg-primary"
              textColor="text-white"
              width="w-max"
              class="mt-4"
              @click.native="showCertificateResult = true"
            />
            <div v-else class="mt-4 w-8/12 flex flex-col justify-end items-end">
              <div class="w-full flex justify-end items-center">
                <span class="text-sm">Certificate Found:</span>
                <div class="w-8/12 ml-4 bg-softGrey rounded-sm p-2">
                  Sr-1F-A04f-5St0-RK
                </div>
              </div>
              <div class="w-full flex justify-end">
                <RoundedButtonVue
                  title="View"
                  bgColor="bg-primary"
                  textColor="text-white"
                  width="w-max"
                  class="mt-4 mr-4"
                  @click.native="viewCertificate"
                />
                <RoundedButtonVue
                  title="Download"
                  bgColor="bg-softGreen"
                  textColor="text-white"
                  width="w-max"
                  class="mt-4"
                  @click.native="showCertificateResult = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicModalsVue>

    <!-- view certificate modals -->
    <BasicModalsVue
      :show="showViewCertificationModal"
      @update:show="showViewCertificationModal = $event"
    >
      <div class="md:w-1024 flex items-center justify-end cursor-pointer">
        <CertificateIconVue
          class="mr-2"
          :height="20"
          :width="20"
          iconColor="#29396A"
        />
        <span class="text-primary">Download Certificate</span>
      </div>
      <CloseIconVue
        :width="20"
        :height="20"
        iconColor="#111"
        class="items-center absolute top-7 right-20 cursor-pointer"
        @click.native="showViewCertificationModal = false"
      />
      <div class="h-full mt-16 flex flex-col items-center justify-start">
        <div class="w-6/12 flex flex-col md:flex-row justify-between">
          <div>
            <img
              class="h-12"
              src="@/assets/images/logo_neo.svg"
              alt="logo_neo"
            />
            <h3 class="mt-6 mb-1 text-primary text-2xl font-bold">
              Statement Of Results
            </h3>
            <h4>English Assessment Test</h4>
            <h5 class="mt-1 text-sm">
              Aligned with the Common European Framework of Reference.
            </h5>
          </div>
          <div>
            <div class="w-max text-center py-4 px-6 bg-softGrey rounded-md">
              <h3 class="text-primary text-xl font-bold">CEFR</h3>
              <h4 class="text-xs font-bold text-primary">LEVEL</h4>
              <img
                class="mt-2 h-16"
                src="@/assets/images/planets/A1.png"
                alt="A1"
              />
            </div>
          </div>
        </div>
        <div class="w-6/12 mt-10 flex flex-col md:flex-row justify-between">
          <div class="w-8/12 mr-4 flex flex-col">
            <span class="text-xs">Name:</span>
            <div class="font-bold bg-softGrey rounded-md mt-1 p-2">Nina12</div>
          </div>
          <div class="w-4/12 flex flex-col">
            <span class="text-xs">Exam Date:</span>
            <div class="font-bold bg-softGrey rounded-md mt-1 p-2">
              14-Feb-23
            </div>
          </div>
        </div>
        <div class="w-6/12 mt-4 flex flex-col md:flex-row justify-between">
          <div class="w-full flex flex-col">
            <span class="text-xs">Similar to:</span>
            <div class="flex justify-between">
              <div
                class="w-4/12 font-bold text-center bg-softGrey rounded-md mt-1 p-2"
              >
                TOEFL 0-54
              </div>
              <div
                class="w-4/12 mx-4 font-bold text-center bg-softGrey rounded-md mt-1 p-2"
              >
                IELTS 0-3
              </div>
              <div
                class="w-4/12 font-bold text-center bg-softGrey rounded-md mt-1 p-2"
              >
                TOEIC 0-400
              </div>
            </div>
          </div>
        </div>
        <div class="w-6/12 mt-10 pb-20 flex flex-col justify-between">
          <div
            class="w-full pb-2 text-primary font-bold border-b border-softGrey flex"
          >
            <div class="w-6/12 mr-2">Skill</div>
            <div class="w-6/12">Proficiency</div>
          </div>
          <div
            class="flex py-5 border-b border-softGrey items-center justify-between"
            v-for="(data, i) in skillProficiency"
            :key="i"
          >
            <div class="w-6/12 mr-2 text-sm">
              <h5 class="font-bold">{{ data.name }}</h5>
              <p>
                {{ data.caption }}
              </p>
            </div>
            <ProficiencyLevelComponentVue
              :proficiency="data.value"
              class="w-6/12"
            />
          </div>
        </div>
      </div>
    </BasicModalsVue>
    <!-- view certificate modals end -->
  </div>
</template>

<script>
import RoundedButtonVue from "./components/buttons/RoundedButton.vue";
import BasicModalsVue from "./components/modals/BasicModals.vue";
import BottomNavigationVue from "./components/navigation/BottomNavigation.vue";
import TopNavigationVue from "./components/navigation/TopNavigation.vue";
import ProficiencyLevelComponentVue from "./components/proficiencylevel/ProficiencyLevelComponent.vue";
import CertificateIconVue from "./components/svgicons/CertificateIcon.vue";
import CloseIconVue from "./components/svgicons/CloseIcon.vue";
import MailIconVue from "./components/svgicons/MailIcon.vue";
import MessageIconVue from "./components/svgicons/MessageIcon.vue";

export default {
  components: {
    TopNavigationVue,
    BottomNavigationVue,
    CertificateIconVue,
    MessageIconVue,
    BasicModalsVue,
    RoundedButtonVue,
    CloseIconVue,
    ProficiencyLevelComponentVue,
    MailIconVue,
  },
  data() {
    return {
      showCertificationModal: false,
      showCertificateResult: false,
      showViewCertificationModal: false,
      skillProficiency: [
        {
          name: "Listening",
          caption:
            "Measures ability to listen, understand and respond to the language expected at this CEFR level.",
          value: 5,
        },
        {
          name: "Social Interaction",
          caption:
            "Measures ability to participate in English conversations and discussions as expected for the CEFR level.",
          value: 6,
        },
        {
          name: "Reading",
          caption:
            "Measures comprehension and retention of information read as expected for the CEFR level.",
          value: 5,
        },
        {
          name: "Grammar",
          caption:
            "Measures mastery of grammatical structures and propoer usage of language in spoken and written form as expected for the CEFR level.",
          value: 6,
        },
        {
          name: "Vocabulary",
          caption:
            "Measures command of essential function and content words in real life situations both spoken and written for the CEFR Level.",
          value: 8,
        },
      ],
    };
  },
  computed: {
    hasWebview() {
      return this.$route.path.includes("/webview");
    },
  },
  methods: {
    closeCertificationModal() {
      this.showCertificateResult = false;
      this.showCertificationModal = false;
    },
    viewCertificate() {
      this.showCertificateResult = false;
      this.showCertificationModal = false;
      this.showViewCertificationModal = true;
    },
  },
};
</script>
<style lang="scss">
// #app {
// font-family: Avenir, Helvetica, Arial, sans-serif;
// -webkit-font-smoothing: antialiased;
// -moz-osx-font-smoothing: grayscale;
// text-align: center;
// color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
