<template>
  <div class="py-3 rounded-full text-center font-semibold cursor-pointer" :class="computedClasses">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Rounded button",
    },
    bgColor: {
      type: String,
      default: "bg-white",
    },
    textColor: {
      type: String,
      default: "text-black",
    },
    width: {
      type: String,
      default: "w-4/12",
    },
    paddingX: {
      type: String,
      default: "px-10",
    },
  },
  computed: {
    computedClasses() {
      return [
        this.bgColor,
        this.textColor,
        this.width,
        this.paddingX,
      ];
    },
  },
};
</script>

<style>
</style>