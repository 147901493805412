import axios from 'axios'

const moduleGetMedia = {
  namespaced: true,
  state: () => ({
    stateMedia: '',
    stateMediaNR: ''
  }),
  mutations: {
    muttMedia (state, muttMedia) {
      state.stateMedia = muttMedia
    },
    muttMediaNR (state, muttMediaNR) {
      state.stateMediaNR = muttMediaNR
    }
  },
  actions: {
    postMediaFiles ({ commit, dispatch }, bodyFormData) {
      // console.log(bodyFormData);
      axios({
				method: 'POST',
				url: 'https://cms.nexgenenglishonline.co/index.php/api/media_files/by_category2',
				data: bodyFormData
			})
      .then(res => {
        // console.log(res);
        commit('muttMedia',{
					data: res
				})
      }).catch(err => {
        console.log(err)
      })
    },
    postMediaFilesNR ({ commit, dispatch }, bodyFormData) {
      // console.log(bodyFormData);
      axios({
				method: 'POST',
				url: 'https://cms.nexgenenglishonline.co/index.php/api/media_files/by_category2',
				data: bodyFormData
			})
      .then(res => {
        // console.log(res);
        commit('muttMediaNR',{
					data: res
				})
      }).catch(err => {
        console.log(err)
      })
    }
  },
  getters: {
    getMedia: state => {
      const data = state.stateMedia

      if (data == '') {
        return false
      } else {
        return data.data.data.data
      }
    },
    getMediaNR: state => {
      const data = state.stateMediaNR

      if (data == '') {
        return false
      } else {
        return data.data.data.data
      }
    }
  }
}
export default moduleGetMedia
