<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 31.991 40.02"
  >
    <g id="Group_1728" data-name="Group 1728" transform="translate(-9.009 -5)">
      <path
        id="Path_1559"
        data-name="Path 1559"
        d="M15,13.57v1.48a2.493,2.493,0,0,0,.2,4.92,10,10,0,0,0,19.6,0,2.493,2.493,0,0,0,.2-4.92V10.98A5.983,5.983,0,0,0,29.02,5H13a1,1,0,0,0-1,1V8.99a5.029,5.029,0,0,0,3,4.59ZM33,18a8,8,0,0,1-16,0V14H33Z"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_241"
        data-name="Ellipse 241"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(20 16)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_242"
        data-name="Ellipse 242"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(27 16)"
        :fill="iconColor"
      />
      <path
        id="Path_1560"
        data-name="Path 1560"
        d="M36.66,31.75l.04-.04a1,1,0,0,0-.11-1.51l-1.27-.95a4.958,4.958,0,0,0-6.45.46l-.56.56-4.06,3.77a1.09,1.09,0,0,0-.26-.03,3.009,3.009,0,0,1-3-3,.992.992,0,0,0-.36-.77.977.977,0,0,0-.82-.21l-5,.95a1.142,1.142,0,0,0-.26.09,9.959,9.959,0,0,0-5.54,8.96v3.99a.942.942,0,0,0,.28.71.99.99,0,0,0,.71.29h5a1,1,0,0,0,.68-.27l4.49-4.17a.789.789,0,0,0,.12.15l2.29,2.29-.29.29a1,1,0,0,0,1.41,1.41L35.21,33.21a7.954,7.954,0,0,1,3.8,6.81v2.99H34v-3a1,1,0,0,0-2,0v4a1,1,0,0,0,1,1h7a1,1,0,0,0,1-1V40.02a9.9,9.9,0,0,0-4.34-8.26Zm-6.96-.04.59-.59a2.949,2.949,0,0,1,3.83-.27l.33.25a.936.936,0,0,0-.34.37l-8.12,8.12L23.7,37.3h0l6.01-5.58ZM14.6,43H11V40.01a7.992,7.992,0,0,1,4.32-7.11l3.81-.72a5.01,5.01,0,0,0,3.28,3.56Zm7.1-3.71s-.06-.05-.09-.07l.62-.58s.03.04.05.07L24.57,41l-.59.59L21.69,39.3Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#a102ff",
    },
  },
};
</script>

<style>
</style>