<template>
  <div class="relative">
    <ul class="flex">
      <li
        v-for="(data, i) in emptyBox"
        :key="i"
        class="w-6 h-6 mx-1 rounded-md bg-softGrey"
      ></li>
    </ul>
    <ul class="flex absolute top-0">
      <li
        v-for="(data, i) in proficiency"
        :key="i"
        class="w-6 h-6 mx-1 rounded-md bg-primary"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["proficiency"],
  data() {
    return {
      emptyBox: 10,
    };
  },
};
</script>

<style>
</style>