<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 31.991 39.01"
  >
    <g id="Group_1727" data-name="Group 1727" transform="translate(-8.989 -6)">
      <path
        id="Path_1557"
        data-name="Path 1557"
        d="M15,14.74v1.31a2.493,2.493,0,0,0,.2,4.92,10,10,0,0,0,19.6,0,2.493,2.493,0,0,0,.2-4.92V11.98A5.983,5.983,0,0,0,29.02,6H16.5A4.5,4.5,0,0,0,15,14.74ZM33,19a8,8,0,0,1-16,0V15H33Z"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_239"
        data-name="Ellipse 239"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(20 17)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_240"
        data-name="Ellipse 240"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(27 17)"
        :fill="iconColor"
      />
      <path
        id="Path_1558"
        data-name="Path 1558"
        d="M36,31.34v-.1A3.246,3.246,0,0,0,32.76,28h-.53a3.235,3.235,0,0,0-3,2.03.928.928,0,0,0-.24-.03,1,1,0,0,0-1,1,3,3,0,0,1-6,0,1,1,0,0,0-1-1,.986.986,0,0,0-.24.03,3.235,3.235,0,0,0-3-2.03h-.53a3.246,3.246,0,0,0-3.24,3.24v.12a9.958,9.958,0,0,0-4.99,8.66v3.99a.942.942,0,0,0,.28.71.99.99,0,0,0,.71.29h5a1,1,0,0,0,1-1v-4h3v4a1,1,0,0,0,2,0V34a4.985,4.985,0,0,0,8,0V44.01a1,1,0,0,0,2,0v-4h3v4a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V40.02a9.9,9.9,0,0,0-5-8.67ZM11.01,40.01A7.978,7.978,0,0,1,14,33.76v9.23H11V40Zm5-2.01V31.24A1.24,1.24,0,0,1,17.25,30h.53a1.24,1.24,0,0,1,1.24,1.24V38h-3Zm15,0V31.24A1.24,1.24,0,0,1,32.25,30h.53a1.24,1.24,0,0,1,1.24,1.24V38h-3Zm8,5h-3V33.75a7.956,7.956,0,0,1,3.01,6.26V43Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#00c6fe",
    },
  },
};
</script>

<style>
</style>