<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 30 39"
  >
    <g id="Group_1725" data-name="Group 1725" transform="translate(-10 -5)">
      <circle
        id="Ellipse_237"
        data-name="Ellipse 237"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(19 18)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_238"
        data-name="Ellipse 238"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(28 18)"
        :fill="iconColor"
      />
      <path
        id="Path_1555"
        data-name="Path 1555"
        d="M14.41,21.98a11,11,0,0,0,21.18,0A2.737,2.737,0,0,0,38,19.26v-.52a2.751,2.751,0,0,0-2-2.64V15A10.016,10.016,0,0,0,26,5H24A10.016,10.016,0,0,0,14,15v1.1a2.742,2.742,0,0,0-2,2.64v.52A2.745,2.745,0,0,0,14.41,21.98ZM24.98,28A8.992,8.992,0,0,1,16,19.02V16h1a16.187,16.187,0,0,0,8.92-2.71A11.144,11.144,0,0,0,33.25,16h.76v3.02A9,9,0,0,1,24.99,28Z"
        :fill="iconColor"
      />
      <path
        id="Path_1556"
        data-name="Path 1556"
        d="M30.96,31H29a1,1,0,0,0-1,1,3,3,0,0,1-6,0,1,1,0,0,0-1-1H19.04A9.056,9.056,0,0,0,10,40.04V43a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V33.09A7.177,7.177,0,0,1,19.04,33H20V43a1,1,0,0,0,2,0V35.99a4.96,4.96,0,0,0,6,0V43a1,1,0,0,0,2,0V33h.96a6,6,0,0,1,1.04.09V43a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V40.04A9.056,9.056,0,0,0,30.96,31ZM16,42H12V40.04a7.051,7.051,0,0,1,4-6.34Zm22,0H34V33.7a7.051,7.051,0,0,1,4,6.34Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#00b6a1",
    },
  },
};
</script>

<style>
</style>