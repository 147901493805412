import axios from 'axios'

const moduleGetCertification = {
    namespaced: true,
    state: () => ({
        stateCert: '',
    }),
    mutations: {
        muttCert(state, muttCert) {
            state.stateCert = muttCert
        },
        clearState(state) {
            state.stateCert = ''
        }
    },
    actions: {
        getCertification({ commit, dispatch }, { cert_id }) {
            axios({
                method: 'GET',
                url: `${process.env.VUE_APP_CERTAPI_BASEURL}/api/v1/certificate/check/${cert_id}`,
                headers: {
                    'Accept': '*/*'
                }
            })
                .then(res => {
                    commit('muttCert', {
                        data: res
                    })
                    // console.log(res);
                }).catch(err => {
                    commit('muttCert', {
                        data: err.response.data
                    })
                    console.log(err)
                })
        },
    },
    getters: {
        gettersCert: state => {
            const data = state.stateCert

            if (data == '') {
                return false
            } else {
                return data.data
            }
        },
    }
}
const initialState = {
    stateCert: ''
};

export default moduleGetCertification