<template>
  <component
    :is="
      isExternal || to === '/dynedstudentcertificateverification'
        ? 'a'
        : 'router-link'
    "
    :href="
      isExternal || to === '/dynedstudentcertificateverification'
        ? to
        : undefined
    "
    :to="
      isExternal || to === '/dynedstudentcertificateverification'
        ? undefined
        : to
    "
    :target="
      isExternal || to === '/dynedstudentcertificateverification'
        ? '_blank'
        : undefined
    "
    :rel="
      isExternal || to === '/dynedstudentcertificateverification'
        ? 'noopener noreferrer'
        : undefined
    "
    class="app-link"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>


<script>
export default {
  name: "AppLink",

  props: {
    to: {
      type: [String, Object],
      required: true,
    },
  },

  computed: {
    isExternal() {
      if (typeof this.to === "string") {
        return this.to.startsWith("http");
      }

      return false;
    },
  },
};
</script>


<style>
.app-link {
  /* Your styles for the link */
}
</style>