<template>
  <svg
    id="menu"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 23 23"
  >
    <path
      id="Rectangle_67"
      data-name="Rectangle 67"
      d="M-.307-1.49H7.629A1.183,1.183,0,0,1,8.812-.307V7.629A1.183,1.183,0,0,1,7.629,8.812H-.307A1.183,1.183,0,0,1-1.49,7.629V-.307A1.183,1.183,0,0,1-.307-1.49ZM6.447.875H.875V6.447H6.447Z"
      transform="translate(1.49 1.49)"
      :fill="iconColor"
    />
    <path
      id="Rectangle_68"
      data-name="Rectangle 68"
      d="M-.307-1.49H7.629A1.183,1.183,0,0,1,8.812-.307V7.629A1.183,1.183,0,0,1,7.629,8.812H-.307A1.183,1.183,0,0,1-1.49,7.629V-.307A1.183,1.183,0,0,1-.307-1.49ZM6.447.875H.875V6.447H6.447Z"
      transform="translate(14.188 1.49)"
      :fill="iconColor"
    />
    <path
      id="Rectangle_69"
      data-name="Rectangle 69"
      d="M-.307-1.49H7.629A1.183,1.183,0,0,1,8.812-.307V7.629A1.183,1.183,0,0,1,7.629,8.812H-.307A1.183,1.183,0,0,1-1.49,7.629V-.307A1.183,1.183,0,0,1-.307-1.49ZM6.447.875H.875V6.447H6.447Z"
      transform="translate(1.49 14.188)"
      :fill="iconColor"
    />
    <path
      id="Path_32"
      data-name="Path 32"
      d="M22.683,31.812A1.183,1.183,0,0,1,21.5,30.629V22.693a1.183,1.183,0,0,1,1.183-1.183h7.937a1.183,1.183,0,1,1,0,2.365H23.865v6.754A1.183,1.183,0,0,1,22.683,31.812Z"
      transform="translate(-8.802 -8.812)"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 32,
    },
    height: {
      type: String,
      default: 32,
    },
    iconColor: {
      type: String,
      default: "#29396A",
    },
  },
};
</script>

<style>
</style>