<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 44.165 38"
  >
    <g id="Group_1740" data-name="Group 1740" transform="translate(-2.92 -6)">
      <path
        id="Path_1575"
        data-name="Path 1575"
        d="M46.67,18.08c-1.1-3.44-4.26-5.96-8.93-7.16a18.98,18.98,0,0,0-31.29,10l-.02.02c-3.09,3.67-4.19,7.57-3.1,10.98,1.12,3.51,4.41,6.01,8.94,7.17a18.983,18.983,0,0,0,31.27-9.95c3.04-3.59,4.26-7.53,3.13-11.06Zm-1.43.46a9.035,9.035,0,0,1-1.27,7.38c.01-.3.02-.61.02-.92a18.89,18.89,0,0,0-4.11-11.78,8.987,8.987,0,0,1,5.35,5.32Zm-3.41,7.37c-.05,0-5.1.8-5.87-2.26-.74-2.96,3.91-5.56,4.43-5.84A16.972,16.972,0,0,1,41.99,25c0,.3,0,.6-.02.89h-.14ZM19.67,8.86a12.07,12.07,0,0,0-.02,4.94c.31,2.09.54,3.6-1.36,5.5-3.32,3.32-9.11,2.83-10.03,2.73A17.059,17.059,0,0,1,19.67,8.86ZM4.76,31.46a9.01,9.01,0,0,1,1.27-7.4c-.01.31-.02.62-.02.93a18.856,18.856,0,0,0,4.07,11.73,9.008,9.008,0,0,1-5.31-5.27ZM25,42a16.972,16.972,0,0,1-8.41-2.23c.62.04,1.25.07,1.9.07a32.968,32.968,0,0,0,5.64-.5.751.751,0,1,0-.26-1.48,28.264,28.264,0,0,1-9.92.05A16.968,16.968,0,0,1,8,25q0-.495.03-.99a16.592,16.592,0,0,0,1.95.1c2.54,0,6.81-.49,9.72-3.41,2.61-2.61,2.26-4.95,1.93-7.21a8.945,8.945,0,0,1,.29-5.13l.03-.09a16.929,16.929,0,0,1,17.48,7.77c-.88.46-6.53,3.63-5.41,8.08.51,2.06,2.14,3.35,4.69,3.75a11.431,11.431,0,0,0,1.68.12c.56,0,1.02-.04,1.33-.07-.07.4-.15.8-.25,1.2a27.626,27.626,0,0,1-7.14,5.21.746.746,0,0,0,.35,1.41.852.852,0,0,0,.35-.08,31.985,31.985,0,0,0,5.36-3.49,17.016,17.016,0,0,1-15.41,9.82Z"
        :fill="iconColor"
      />
      <path
        id="Path_1576"
        data-name="Path 1576"
        d="M29,26a3,3,0,1,0-3,3A3.009,3.009,0,0,0,29,26Zm-4,0a1,1,0,1,1,1,1A1,1,0,0,1,25,26Z"
        :fill="iconColor"
      />
      <path
        id="Path_1577"
        data-name="Path 1577"
        d="M30.09,35.24l-1.29.38a.277.277,0,0,1-.18,0l-1.38-.42a.277.277,0,0,0-.18,0l-1.94.57a.17.17,0,0,0-.07.29l.85.86.57,1.92-.27,1.07a.176.176,0,0,0,.22.21l1.93-.57a.307.307,0,0,0,.15-.1l.93-1.12a.613.613,0,0,1,.15-.1l1.29-.38a2.313,2.313,0,0,0,1.37-1.1l.47-.86-.86-.47a2.314,2.314,0,0,0-1.75-.19Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#3dd3bd",
    },
  },
};
</script>

<style>
</style>