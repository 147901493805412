import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index';
import { mapGetters } from 'vuex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/neoassess',
    name: 'NeoAssess',
    component: () => import(/* webpackChunkName: "neoassess" */ '../views/NeoAssess.vue')
  },
  {
    path: '/certification',
    name: 'Certification',
    component: () => import(/* webpackChunkName: "certification" */ '../views/Certification.vue')
  },
  {
    path: '/elementaryschool',
    name: 'ElementarySchool',
    component: () => import(/* webpackChunkName: "elementaryschool" */ '../views/ElementarySchool.vue')
  },
  {
    path: '/preschool',
    name: 'PreSchool',
    component: () => import(/* webpackChunkName: "preschool" */ '../views/PreSchool.vue')
  },
  {
    path: '/higherlearning',
    name: 'HigherLearning',
    component: () => import(/* webpackChunkName: "higherlearning" */ '../views/HigherLearning.vue')
  },
  {
    path: '/highschool',
    name: 'HighSchool',
    component: () => import(/* webpackChunkName: "highschool" */ '../views/HighSchool.vue')
  },
  {
    path: '/middleschool',
    name: 'MiddleSchool',
    component: () => import(/* webpackChunkName: "middleschool" */ '../views/MiddleSchool.vue')
  },
  {
    path: '/teacher',
    name: 'Teacher',
    component: () => import(/* webpackChunkName: "teacher" */ '../views/Teacher.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/humanresources',
    name: 'HumanResources',
    component: () => import(/* webpackChunkName: "humanresources" */ '../views/HumanResources.vue')
  },
  {
    path: '/nurses',
    name: 'Nurses',
    component: () => import(/* webpackChunkName: "nurses" */ '../views/Nurses.vue')
  },
  {
    path: '/students',
    name: 'Stundets',
    component: () => import(/* webpackChunkName: "students" */ '../views/Students.vue')
  },
  {
    path: '/schooluser',
    name: 'SchoolUser',
    component: () => import(/* webpackChunkName: "schooluser" */ '../views/SchoolUser.vue')
  },
  {
    path: '/businessuser',
    name: 'BusinessUser',
    component: () => import(/* webpackChunkName: "businessuser" */ '../views/BusinessUser.vue')
  },
  {
    path: '/becomeagent',
    name: 'BecomeAgent',
    component: () => import(/* webpackChunkName: "becomeagent" */ '../views/BecomeAgent.vue')
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contactus" */ '../views/ContactUs.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/faqneoassesen',
    name: 'FaqNeoAssesEn',
    component: () => import(/* webpackChunkName: "faqneoassesen" */ '../views/FaqNeoAssesEn.vue')
  },
  {
    path: '/faqneoassesportuguese',
    name: 'FaqNeoAssesPortuguese',
    component: () => import(/* webpackChunkName: "faqneoassesportuguese" */ '../views/FaqNeoAssesPortuguese.vue')
  },
  {
    path: '/faqneoassessp',
    name: 'FaqNeoAssesSp',
    component: () => import(/* webpackChunkName: "faqneoassessp" */ '../views/FaqNeoAssesSp.vue')
  },
  {
    path: '/nexgeneula',
    name: 'EulaAgreement',
    component: () => import(/* webpackChunkName: "EulaAgreement" */ '../views/EulaAgreement.vue')
  },
  {
    path: '/nexgeneula/webview',
    name: 'EulaAgreementWA',
    component: () => import(/* webpackChunkName: "EulaAgreement" */ '../views/EulaAgreementWA.vue')
  },
  {
    path: '/nexgeneulacn',
    name: 'EulaAgreementCn',
    component: () => import(/* webpackChunkName: "EulaAgreementCn" */ '../views/EulaAgreementCn.vue')
  },
  {
    path: '/nexgeneulacn/webview',
    name: 'EulaAgreementCnWA',
    component: () => import(/* webpackChunkName: "EulaAgreementCn" */ '../views/EulaAgreementCnWA.vue')
  },
  {
    path: '/nexgeneulath',
    name: 'EulaAgreementTh',
    component: () => import(/* webpackChunkName: "EulaAgreementTh" */ '../views/EulaAgreementTh.vue')
  },
  {
    path: '/nexgeneulasp',
    name: 'EulaAgreementSp',
    component: () => import(/* webpackChunkName: "EulaAgreementSp" */ '../views/EulaAgreementSp.vue')
  },
  {
    path: '/nexgeneulaid',
    name: 'EulaAgreementId',
    component: () => import(/* webpackChunkName: "EulaAgreementId" */ '../views/EulaAgreementId.vue')
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/privacypolicy/webview',
    name: 'PrivacyPolicyWA',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicyWA.vue')
  },
  {
    path: '/privacypolicycn',
    name: 'PrivacyPolicyCn',
    component: () => import(/* webpackChunkName: "PrivacyPolicyCn" */ '../views/PrivacyPolicyCn.vue')
  },
  {
    path: '/privacypolicycn/webview',
    name: 'PrivacyPolicyCnWA',
    component: () => import(/* webpackChunkName: "PrivacyPolicyCn" */ '../views/PrivacyPolicyCnWA.vue')
  },
  {
    path: '/myneoprivacypolicy/webview',
    name: 'MyneoPrivacyPolicy',
    component: () => import(/* webpackChunkName: "myneoprivacypolicy" */ '../views/MyneoPrivacyPolicy.vue')
  },
  {
    path: '/myneoprivacypolicycn/webview',
    name: 'MyneoPrivacyPolicyCn',
    component: () => import(/* webpackChunkName: "myneoprivacypolicycn" */ '../views/MyneoPrivacyPolicyCn.vue')
  },
  {
    path: '/support',
    name: 'SystemRequirements',
    component: () => import(/* webpackChunkName: "systemrequirements" */ '../views/SystemRequirements.vue')
  },
  {
    path: '/neostudentcertificateverification',
    name: 'StudentCertificationVerification',
    component: () => import(/* webpackChunkName: "studentcertificationverification" */ '../views/StudentCertificationVerification.vue')
  },
  {
    path: '/dynedstudentcertificateverification',
    name: 'DynEdStudentCertificateVerification',
    component: () => import(/* webpackChunkName: "dynedstudentcertificateverification" */ '../views/DynEdStudentCertificateVerification.vue')
  },
  {
    path: '/neoagentresources',
    name: 'NeoAgentResources',
    component: () => import(/* webpackChunkName: "neoagentresources" */ '../views/NeoAgentResources.vue'),
    beforeEnter(to, from, next) {
      const authUser = store.getters.getAuth
      if (authUser == true) {
        return next('/medialibrary')
      } else if (authUser == false) {
        return next()
      } else {
        return next()
      }
    }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue')
  },
  // {
  //   path: '/medialibrary',
  //   name: 'MediaLibrary',
  //   component: () => import(/* webpackChunkName: "medialibrary" */ '../views/MediaLibrary.vue')
  // },
  {
    path: '/medialibrary',
    name: 'MediaLibrary',
    component: () => import(/* webpackChunkName: "MediaLibrary" */ '../views/MediaLibrary.vue'),
    beforeEnter(to, from, next) {
      const authUser = store.getters.getAuth
      if (authUser == true) {
        return next()
      } else if (authUser == false) {
        return next('/neoagentresources')
      } else {
        return next('/neoagentresources')
      }
    }
  },
  {
    path: '/mediageneral',
    name: 'MediaGeneral',
    component: () => import(/* webpackChunkName: "mediageneral" */ '../views/MediaGeneral.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
