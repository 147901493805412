<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 38 43"
  >
    <g id="Group_1758" data-name="Group 1758" transform="translate(-5.99 -4)">
      <path
        id="Path_1565"
        data-name="Path 1565"
        d="M13,16.5a2.489,2.489,0,0,0,2.2,2.47,10,10,0,0,0,19.6,0,2.493,2.493,0,0,0,.2-4.92V9.98A5.983,5.983,0,0,0,29.02,4H17.32a4.317,4.317,0,0,0-4.31,4.31,4.662,4.662,0,0,0,2,3.83v1.9a2.5,2.5,0,0,0-2,2.45ZM25,25a8.012,8.012,0,0,1-7.99-7.75h1.25a3.25,3.25,0,0,0,6.48,0h.53a3.25,3.25,0,0,0,6.48,0H33A8,8,0,0,1,25.01,25Zm-5.25-8a1.75,1.75,0,1,1,1.75,1.75A1.758,1.758,0,0,1,19.75,17Zm7,0a1.75,1.75,0,1,1,1.75,1.75A1.758,1.758,0,0,1,26.75,17ZM33,13v2.75H31.5a3.25,3.25,0,0,0-6,0h-1a3.25,3.25,0,0,0-6,0H17V13Z"
        :fill="iconColor"
      />
      <path
        id="Path_1566"
        data-name="Path 1566"
        d="M25,36a1.94,1.94,0,0,1,.26-.98A1.1,1.1,0,0,0,25,35a2,2,0,0,0,0,4,1.986,1.986,0,0,0,1.74-1.02A1.99,1.99,0,0,1,25,36Z"
        :fill="iconColor"
      />
      <path
        id="Path_1567"
        data-name="Path 1567"
        d="M38.37,30.67a1.141,1.141,0,0,0-.41-.07A3.027,3.027,0,0,0,34.97,28H15.02a3.027,3.027,0,0,0-2.99,2.6,1.179,1.179,0,0,0-.41.07,8.952,8.952,0,0,0-5.63,8.34V44a1,1,0,0,0,1,1h5v1a1,1,0,0,0,1,1h24a1,1,0,0,0,1-1V45h5a1,1,0,0,0,1-1V39.01a8.952,8.952,0,0,0-5.63-8.34ZM12,43H8V39.01a6.971,6.971,0,0,1,4-6.32Zm24,0H14V31.02A1.024,1.024,0,0,1,15.02,30H34.98A1.024,1.024,0,0,1,36,31.02Zm6,0H38V32.69a6.96,6.96,0,0,1,4,6.32Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#ff8092",
    },
  },
};
</script>

<style>
</style>