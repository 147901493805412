<template>
  <div ref="lottieContainer"></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  props: {
    animationData: Object, // Lottie JSON animation data
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.initLottie();
  },
  methods: {
    initLottie() {
      this.lottieInstance = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        animationData: this.animationData,
      });
    },
  },
  beforeDestroy() {
    this.lottieInstance.destroy();
  },
};
</script>