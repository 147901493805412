<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 36.02 40"
  >
    <g id="Group_1731" data-name="Group 1731" transform="translate(-7.01 -5)">
      <path
        id="Path_1561"
        data-name="Path 1561"
        d="M15,13.57v1.48a2.493,2.493,0,0,0,.2,4.92,10,10,0,0,0,19.6,0,2.493,2.493,0,0,0,.2-4.92V10.98A5.983,5.983,0,0,0,29.02,5H13a1,1,0,0,0-1,1V8.99a5.029,5.029,0,0,0,3,4.59ZM33,18a8,8,0,0,1-16,0V14H33Z"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_243"
        data-name="Ellipse 243"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(20 16)"
        :fill="iconColor"
      />
      <circle
        id="Ellipse_244"
        data-name="Ellipse 244"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(27 16)"
        :fill="iconColor"
      />
      <path
        id="Path_1562"
        data-name="Path 1562"
        d="M31.98,29H30a1,1,0,0,0-1,1,3,3,0,0,1-1.2,2.4l-6.68-1.49a1,1,0,0,0-.02-.16,3.142,3.142,0,0,1-.09-.75,1,1,0,0,0-1-1H18.03A11.034,11.034,0,0,0,7.01,40.02V44a1,1,0,0,0,1,1h7a.69.69,0,0,0,.25-.04.076.076,0,0,1,.04.01.82.82,0,0,0,.22.02,1.013,1.013,0,0,0,.98-.78L19.12,32.5l13.66,3.05-.22.98-10.74-2.4a1,1,0,0,0-1.19.76l-1.98,8.89a1,1,0,0,0,.76,1.19.82.82,0,0,0,.22.02,1.013,1.013,0,0,0,.98-.78l1.77-7.91,10.73,2.4h0l2.93.65-.99,4.42a.943.943,0,0,0,.2.84.991.991,0,0,0,.78.37h6a1,1,0,0,0,1-1V40A11.034,11.034,0,0,0,32.01,28.98ZM14.7,43H8.99V40.02a9.03,9.03,0,0,1,8.39-8.99c0,.02-.02.04-.02.06Zm26.29,0H37.24l.93-4.18a.985.985,0,0,0-.13-.75,1.022,1.022,0,0,0-.63-.44l-2.93-.65.44-1.95a1,1,0,0,0-.76-1.19l-4.12-.92a4.971,4.971,0,0,0,.83-1.9h1.1a9.028,9.028,0,0,1,9.02,9.02v2.98Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#ff00ef",
    },
  },
};
</script>

<style>
</style>