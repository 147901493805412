var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.isExternal || _vm.to === '/dynedstudentcertificateverification'
      ? 'a'
      : 'router-link',_vm._g(_vm._b({tag:"component",staticClass:"app-link",attrs:{"href":_vm.isExternal || _vm.to === '/dynedstudentcertificateverification'
      ? _vm.to
      : undefined,"to":_vm.isExternal || _vm.to === '/dynedstudentcertificateverification'
      ? undefined
      : _vm.to,"target":_vm.isExternal || _vm.to === '/dynedstudentcertificateverification'
      ? '_blank'
      : undefined,"rel":_vm.isExternal || _vm.to === '/dynedstudentcertificateverification'
      ? 'noopener noreferrer'
      : undefined}},'component',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }