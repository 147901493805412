<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 38 40.88"
  >
    <g id="Group_1738" data-name="Group 1738" transform="translate(-6 -5.12)">
      <path
        id="Path_1571"
        data-name="Path 1571"
        d="M35,29H31a1,1,0,0,0-1,1,3.009,3.009,0,0,1-3,3H23a3.009,3.009,0,0,1-3-3,1,1,0,0,0-1-1H15a9.014,9.014,0,0,0-9,9v7a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V31h2.1A5.009,5.009,0,0,0,23,35h4a5.017,5.017,0,0,0,4.9-4H34V45a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V38A9.014,9.014,0,0,0,35,29ZM8,40h4a1,1,0,0,0,0-2H8a7.013,7.013,0,0,1,6-6.93V44H8Zm28,4V31.07A7.013,7.013,0,0,1,42,38H38a1,1,0,0,0,0,2h4v4Z"
        :fill="iconColor"
      />
      <path
        id="Path_1572"
        data-name="Path 1572"
        d="M30,38H20a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H30a1,1,0,0,0,1-1V39A1,1,0,0,0,30,38Zm-1,6H21V40h8Z"
        :fill="iconColor"
      />
      <path
        id="Path_1573"
        data-name="Path 1573"
        d="M13.81,21.53h.21a11.876,11.876,0,0,0,21.96,0h.21a3.717,3.717,0,0,0,3.74-3.69V16a3.724,3.724,0,0,0-3.74-3.69,1.171,1.171,0,0,0-.26.04,11.877,11.877,0,0,0-21.86,0,1.087,1.087,0,0,0-.26-.04A3.717,3.717,0,0,0,10.07,16v1.84a3.724,3.724,0,0,0,3.74,3.69ZM25,27.12a10.09,10.09,0,0,1-8-3.95,99.386,99.386,0,0,0,16,0A10.12,10.12,0,0,1,25,27.12Zm9.38-11.54a2.34,2.34,0,1,1-2.34-2.3A2.322,2.322,0,0,1,34.38,15.58Zm3.7.42v1.84a1.848,1.848,0,0,1-1.49,1.79,12.048,12.048,0,0,0,.3-2.63,11.8,11.8,0,0,0-.34-2.8A1.851,1.851,0,0,1,38.08,16ZM25.01,6.88a10.09,10.09,0,0,1,8,3.95,99.386,99.386,0,0,0-16,0A10.12,10.12,0,0,1,25.01,6.88ZM11.94,16a1.859,1.859,0,0,1,1.53-1.8,11.732,11.732,0,0,0-.34,2.8,11.466,11.466,0,0,0,.3,2.63,1.848,1.848,0,0,1-1.49-1.79Z"
        :fill="iconColor"
      />
      <path
        id="Path_1574"
        data-name="Path 1574"
        d="M23,43h4a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#83a6ff",
    },
  },
};
</script>

<style>
</style>