import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './app.css';
import Zendesk from '@dansmaculotte/vue-zendesk'

Vue.config.productionTip = false

Vue.use(Zendesk, {
  key: 'c9c4cc46-85c5-409f-9dc7-e961dd46a194',
  disabled: false,
  hideOnLoad: false,
  settings: {
    webWidget: {
      zIndex: 9
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
