<template>
  <div class="modal w-full h-screen" v-if="show">
    <div class="modal-content w-full h-screen overflow-y-auto fixed z-20">
      <!-- Modal content goes here -->
      <slot></slot>
    </div>
    <div class="modal-overlay" @click="close"></div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit('update:show', false); // Emit event to close the modal
    },
  },
};
</script>

<style>
/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
}
</style>