<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 45.133 39.153"
  >
    <g
      id="Group_1751"
      data-name="Group 1751"
      transform="translate(-2.428 -5.417)"
    >
      <g id="Group_1750" data-name="Group 1750">
        <path
          id="Path_1583"
          data-name="Path 1583"
          d="M26.1,43.84a3.657,3.657,0,0,1-2.59-1.07l1.41-1.41a1.585,1.585,0,0,0,2.09.18,1.508,1.508,0,0,0,.55-1.09,1.484,1.484,0,0,0-.44-1.13h0a1,1,0,0,1,1.41-1.41h0a1.528,1.528,0,0,0,2.12,0,1.509,1.509,0,0,0,0-2.12.982.982,0,0,1-.29-.71,1.024,1.024,0,0,1,.29-.71,1,1,0,0,1,1.41,0,1.5,1.5,0,1,0,2.12-2.12h0a1,1,0,0,1,1.41-1.41h0a1.494,1.494,0,0,0,2.22-.11,1.575,1.575,0,0,0-.18-2.09l-6.81-6.81a9.565,9.565,0,0,0-5.13,2.65,4.493,4.493,0,0,1-6.46-.1,4.611,4.611,0,0,1,.2-6.37l3.77-3.77a4.448,4.448,0,0,0-5.95.29L10.8,20.98a4.448,4.448,0,0,0,0,6.28L9.39,28.67a6.44,6.44,0,0,1,0-9.1l6.45-6.45a6.422,6.422,0,0,1,9.1,0l.4.4a.99.99,0,0,1,.29.71,1.033,1.033,0,0,1-.29.71l-4.49,4.49a2.6,2.6,0,0,0-.16,3.58,2.471,2.471,0,0,0,1.78.79,2.539,2.539,0,0,0,1.81-.73,11.568,11.568,0,0,1,6.51-3.25l.25-.04a.991.991,0,0,1,.85.28l7.17,7.17A3.59,3.59,0,0,1,39.37,32a3.508,3.508,0,0,1-2.54,1.27h-.2v.04a3.5,3.5,0,0,1-3.54,3.5v.04a3.455,3.455,0,0,1-1.02,2.47,3.54,3.54,0,0,1-2.51,1.03v.2a3.544,3.544,0,0,1-1.27,2.54,3.379,3.379,0,0,1-2.18.77Z"
          :fill="iconColor"
        />
        <path
          id="Path_1584"
          data-name="Path 1584"
          d="M9.09,22.28a1.033,1.033,0,0,1-.71-.29L2.72,16.33a1,1,0,0,1,0-1.41l8.49-8.49a1,1,0,0,1,1.41,0l5.66,5.66a1,1,0,0,1,0,1.41L9.79,21.99a.99.99,0,0,1-.71.29ZM4.85,15.62l4.24,4.24,7.07-7.07L11.92,8.55,4.85,15.62Z"
          :fill="iconColor"
        />
        <path
          id="Path_1585"
          data-name="Path 1585"
          d="M6.26,19.45a1.024,1.024,0,0,1-.71-.29,1,1,0,0,1,0-1.41l8.49-8.49a1,1,0,0,1,1.41,1.41L6.96,19.16a.982.982,0,0,1-.71.29Z"
          :fill="iconColor"
        />
        <g id="Group_1749" data-name="Group 1749">
          <path
            id="Path_1586"
            data-name="Path 1586"
            d="M18.99,41.04a3.483,3.483,0,0,1-3.5-3.53,3.557,3.557,0,0,1-2.51-1.02,3.482,3.482,0,0,1-1.02-2.51h-.04a3.5,3.5,0,1,1,0-7,3.5,3.5,0,0,1,3.5,3.54,3.54,3.54,0,0,1,2.51,1.02,3.482,3.482,0,0,1,1.02,2.51h.04a3.5,3.5,0,0,1,2.47,5.98,3.516,3.516,0,0,1-2.47,1.02Zm-1.05-4.57-.01.01a1.492,1.492,0,1,0,.01-.01ZM14.4,32.93l-.01.01a1.5,1.5,0,0,0,2.11,2.13l.01-.01a1.5,1.5,0,0,0-2.11-2.13Zm-2.49-3.96a1.5,1.5,0,1,0,1.05,2.57h.01a1.558,1.558,0,0,0,.44-1.07,1.5,1.5,0,0,0-1.5-1.5Z"
            :fill="iconColor"
          />
          <path
            id="Path_1587"
            data-name="Path 1587"
            d="M22.53,44.57a3.5,3.5,0,0,1-2.48-5.97,3.472,3.472,0,0,1,2.47-1.02h0a3.455,3.455,0,0,1,2.47,1.02h0a3.5,3.5,0,0,1-.01,4.95,3.516,3.516,0,0,1-2.47,1.02Zm0-5a1.5,1.5,0,1,0,1.06.44h0a1.508,1.508,0,0,0-1.06-.44Z"
            :fill="iconColor"
          />
        </g>
        <path
          id="Path_1588"
          data-name="Path 1588"
          d="M40.91,21.57a1.024,1.024,0,0,1-.71-.29l-8.49-8.49a.99.99,0,0,1-.29-.71,1.033,1.033,0,0,1,.29-.71l5.66-5.66a1,1,0,0,1,1.41,0l8.49,8.49a.99.99,0,0,1,.29.71,1.033,1.033,0,0,1-.29.71l-5.66,5.66a.982.982,0,0,1-.71.29Zm-7.07-9.49,7.07,7.07,4.24-4.24L38.08,7.84l-4.24,4.24Z"
          :fill="iconColor"
        />
        <path
          id="Path_1589"
          data-name="Path 1589"
          d="M43.74,18.74a1.024,1.024,0,0,1-.71-.29L34.54,9.96a1,1,0,0,1,1.41-1.41l8.49,8.49a1,1,0,0,1,0,1.41.982.982,0,0,1-.71.29Z"
          :fill="iconColor"
        />
        <path
          id="Path_1590"
          data-name="Path 1590"
          d="M38.79,29.35a1.033,1.033,0,0,1-.71-.29l-7.24-7.24a9.565,9.565,0,0,0-5.13,2.65,4.493,4.493,0,0,1-6.46-.1,4.611,4.611,0,0,1,.2-6.37l5.6-5.6a6.384,6.384,0,0,1,4.55-1.89h0a6.367,6.367,0,0,1,4.55,1.89l6.45,6.45a6.44,6.44,0,0,1,0,9.1l-1.11,1.11a.99.99,0,0,1-.71.29Zm-7.6-9.6a1.007,1.007,0,0,1,.71.29l6.89,6.89.4-.4a4.436,4.436,0,0,0,0-6.27l-6.45-6.45a4.4,4.4,0,0,0-3.14-1.3h0a4.4,4.4,0,0,0-3.14,1.3l-5.6,5.6a2.6,2.6,0,0,0-.16,3.58,2.471,2.471,0,0,0,1.78.79,2.539,2.539,0,0,0,1.81-.73A11.568,11.568,0,0,1,30.8,19.8l.25-.04s.1-.01.14-.01Z"
          :fill="iconColor"
        />
      </g>
      <path
        id="Path_1591"
        data-name="Path 1591"
        d="M23.94,35.71a1.033,1.033,0,0,1-.71-.29L20.4,32.59a1,1,0,0,1,0-1.41l7.07-7.07a.99.99,0,0,1,.71-.29h0a1.033,1.033,0,0,1,.71.29l2.83,2.83a1,1,0,0,1,0,1.41l-7.07,7.07A.99.99,0,0,1,23.94,35.71Zm-1.41-3.83,1.41,1.41,5.66-5.66-1.41-1.41-5.66,5.66Z"
        :fill="iconColor"
      />
      <path
        id="Path_1592"
        data-name="Path 1592"
        d="M31.72,22.28a1.024,1.024,0,0,1-.71-.29,1,1,0,0,1,0-1.41l1.41-1.41a1,1,0,1,1,1.41,1.41l-1.41,1.41a.982.982,0,0,1-.71.29Z"
        :fill="iconColor"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 39,
    },
    iconColor: {
      type: String,
      default: "#fb7dff",
    },
  },
};
</script>

<style>
</style>